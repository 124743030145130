import { isDevEnv } from './env';

export const BASE_URL_PROD = 'https://dev.wix.com/digor';

const LOCAL_PORT = 3000;

const LOCAL_HOST_URL = `http://localhost:${LOCAL_PORT}`;

export const getBaseURLClient = (forceProd = false) => {
  const baseURL = isDevEnv() && !forceProd ? LOCAL_HOST_URL : BASE_URL_PROD;

  return baseURL;
};

export const getBaseURLServer = () => {
  return getBaseURLClient();
};
